export enum enumTags {
    BREAKFAST = 'breakfast',
    DINNER = 'dinner',
    CARBS = 'carbs',
    PROTEIN = 'protein',
    VEGGIES = 'veggies',
    FRUIT = 'fruit'
}

export interface IMenuEntry {
    'name': string;
    'pairsWith'?: string[];
    'sides'?: string[];
    'tags'?: string[];
    'variants'?: string[];
}

export interface IMenuData {
    'entrees': IMenuEntry[];
    'sides': IMenuEntry[];
    [enumTags.VEGGIES]: IMenuEntry[];
    [enumTags.FRUIT]: IMenuEntry[];
}

export interface IMenuResponse {
    'records': IMenuResponseRecord[];
}

export interface IMenuResponseRecord {
    'id': string;
    'fields': {
        'id': string;
        'type': string[];
        'name': string;
        'tags'?: string[];
        'variants'?: string;
        'pairsWith'?: string;
        'sides'?: string;
    };
    'createdTime': string;
}
