<section class="section is-6-tablet">
    <div class="container is-fluid">
        <div class="content">
            <h2 class="is-size-3">{{ title }}</h2>
            <div class="menu columns is-mobile is-multiline is-centered">
                <div class="column is-half-mobile is-one-third-tablet" *ngFor="let entree of menu; let i = index;">
                    <div class="card">
                        <header class="card-header has-background-info">
                            <div class="card-header-title has-text-white" [innerHtml]="entree.name" contenteditable></div>
                            <div class="card-header-icon has-text-light" (click)="replaceItem(i)"><i class="fas fa-redo-alt" title="Replace Item"></i></div>
                        </header>
                        <div *ngIf="!!entree.sides" class="card-content">
                            <div class="is-pulled-right has-text-dark" (click)="replaceSides(i)"><i class="fas fa-redo-alt" title="Replace Sides"></i></div>
                            <ul class="sides" contenteditable>
                                <li *ngFor="let side of entree.sides" class="has-text-weight-semibold" [innerHTML]="side"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div><br />
    </div>
    <div class="columns">
        <div *ngIf="dadJoke" class="column is-10 is-offset-1 has-text-centered has-text-weight-semibold has-text-link">{{ dadJoke }}</div>
    </div>
    <div class="container button-footer">
        <button (click)="generateMenu()" class="button is-primary">Generate New Menu</button>
    </div>
</section>
<router-outlet></router-outlet>
