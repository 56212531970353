import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { IMenuData, IMenuResponse, IMenuEntry, enumTags } from './menuData';

interface IDadJokeResponse {
    id: string;
    joke: string;
    status: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public title = 'Menu Generator';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public menu: IMenuEntry[];
    public dadJoke: string;

    private _menuData: IMenuData;
    private _menuDays = 6;
    private _outsideOptions: string[] = [];
    private _seenVariants = new Set();
    private _seenEntrees = new Set();

    constructor(private _http: HttpClient) {}

    ngOnInit() {
        this._getAirtableData()
            .subscribe((menuData) => {
                // console.log('got data');
                this._menuData = menuData;
                if (menuData) { this.generateMenu(); }
            });
    }

    public generateMenu() {
        // console.log('generateMenu()');
        this._seenVariants.clear();
        this._seenEntrees.clear();
        this.menu = this._getEntrees();
        this._getDadJoke();
    }

    public replaceItem(item) {
        this.menu[item] = this._getEntrees(1)[0];
        this._seenVariants.add(this.menu[item].name);
    }

    public replaceSides(item) {
        this.menu[item].sides = this._getSides(item.name);
    }

    private _getSides(entree = '', total = 1): string[] {
        // console.log(`_getSides(${entree},${total})`);
        const sidesEntries: IMenuEntry[] = [];
        if (this._outsideOptions.indexOf(entree) < 0) {
            // initialize sizesEntries with a veggie
            sidesEntries.push(...this._getArrayItems(this._filterSides(this._menuData[enumTags.VEGGIES], entree), 1));
            // add a fruit
            sidesEntries.push(...this._getArrayItems(this._filterSides(this._menuData[enumTags.FRUIT], entree), 1));
        }

        // sides
        sidesEntries.push(...this._getArrayItems(this._filterSides(this._menuData.sides, entree), total));

        const sides: string[] = sidesEntries.map((side: IMenuEntry) => {
            return this._getVariant(side);
        });
        return sides;
    }

    private _filterSides(sides: IMenuEntry[], entree: string): IMenuEntry[] {
        // console.log(`_filterSides(${sides},${entree})`);
        const filteredSides: IMenuEntry[] = sides.filter(side => {
            if (side.pairsWith) {
                return (side.pairsWith[0] === 'ALL') || (side.pairsWith.indexOf(entree) !== -1);
            }
        });
        return filteredSides;
    }

    private _getVariant(source: IMenuEntry, limit: boolean = false) {
        // console.log(`_getVariant(${source},${limit})`);
        let variant: string = source.name;
        if (source.variants) {
            let i = 0;
            do {
                variant = source.variants[this._getRandomInt(source.variants.length)];
                i++;
            } while (this._seenVariants.has(variant) && (i < source.variants.length));
        }
        if (limit && this._seenVariants.has(variant)) {
            // add entree to seenEntrees
            this._seenEntrees.add(source.name);
        }
        return variant;
    }

    private _getEntrees(total = this._menuDays): IMenuEntry[] {
        // console.log(`_getEntrees(${total})`);
        let entrees: IMenuEntry[] = [];
        if (total !== 1) {
            entrees = <IMenuEntry[]>[
                ...this._getArrayItems(this._menuData.entrees, (total - 1)),
                this._menuData.entrees[0]
            ];
        } else {
            entrees = <IMenuEntry[]>this._getArrayItems(this._menuData.entrees, total);
        }
        entrees = entrees.map((entree: IMenuEntry) => {
            if (this._seenEntrees.has(entree.name)) {
                let j = 0;
                do {
                    entree = this._menuData.entrees[this._getRandomInt(this._menuData.entrees.length)];
                    j++;
                } while (this._seenEntrees.has(entree.name) && (j < this._menuData.entrees.length));
            }
            const newEntree = {
                'name': this._getVariant(entree, true),
                'base': entree.name
            };
            this._seenVariants.add(newEntree.name);
            return {
                ...newEntree,
                'sides': entree.sides || this._getSides(newEntree.name, 1)
            };
        });
        return entrees;
    }

    private _getArrayItems(source: IMenuEntry[], total: number): IMenuEntry[] {
        // console.log(`_getArrayItems(${source},${total})`);
        const indexes = this._getRandomInts(
            total,
            source.length
        );
        const newArray: IMenuEntry[] = [];
        indexes.map(
            (index) => {
                if (source[index]) {
                    newArray.push(source[index]);
                }
            }
        );
        return newArray;
    }

    private _getRandomInts(total, max) {
        // console.log(`_getRandomInts(${total},${max})`);
        const randos = [];
        let random = 0;

        for (let j = 0; j < total; j++) {
            random = this._getRandomInt(max);
            // console.log(`randomInt = ${random}`);
            if (randos.indexOf(random) === -1) {
                randos.push(random);
            } else {
                j--;
            }
        }
        return randos;
    }

    private _getRandomInt(max) {
        // console.log(`_getRandomInt(${max})`);
        return Math.floor(Math.random() * Math.floor(max));
    }

    private _getAirtableData(): Observable<IMenuData> {
        const airtableURL = 'https://api.airtable.com/v0';
        const airtableBase = 'appzwwtU5qeiWuL1a';
        const airtableKey = 'pat7Zr9gLDUIlQfZT.a118394309f4405f505948fcac09d33c537fd648a5c40ffdd2a43f341bd7872a';
        const airtableTable = 'menu';
        const dataUrl = [airtableURL, airtableBase, airtableTable].join('/'); //  + '?api_key=' + airtableKey;
        const menuData: IMenuData = { 'entrees': [], 'sides': [], [enumTags.VEGGIES]: [], [enumTags.FRUIT]: [] };
        const headers = new HttpHeaders().set('Authorization', `Bearer ${airtableKey}`);

        return this._http
            .get(dataUrl, { headers })
            .pipe(
                map((data: IMenuResponse) => {
                    data.records.map((item) => {
                        let mdGroup = '';
                        const mdItem: IMenuEntry = {
                            'name': (item.fields.name) ? item.fields.name : null,
                            'tags': (item.fields.tags) ? item.fields.tags : null,
                            'pairsWith': (item.fields.pairsWith) ? this._parseJSON(item.fields.pairsWith) : null,
                            'variants': (item.fields.variants) ? this._parseJSON(item.fields.variants) : null,
                            'sides': (item.fields.sides) ? this._parseJSON(item.fields.sides) : null
                        };
                        for (let i = 0; i < item.fields.type.length; i++) {
                            mdGroup = item.fields.type[i] + 's';
                            if (mdItem.name === 'Eat Out') {
                                this._outsideOptions = mdItem.variants;
                            }
                            if (mdItem.name === 'Leftovers') {
                                menuData['entrees'].splice(0, 0, mdItem);
                            } else if (!!mdItem.tags && mdItem.tags.indexOf(enumTags.FRUIT) > -1) {
                                menuData[enumTags.FRUIT].push(mdItem);
                            } else if (!!mdItem.tags && mdItem.tags.indexOf(enumTags.VEGGIES) > -1) {
                                menuData[enumTags.VEGGIES].push(mdItem);
                            } else {
                                menuData[mdGroup].push(mdItem);
                            }
                        }
                    });
                    return menuData;
                })
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _parseJSON(source: string): any {
        // console.log(`_parseJSON(${source})`);
        const singleQuote = /'/gi;
        const doubleQuote = '"';
        return JSON.parse(source.replace(singleQuote, doubleQuote));
    }

    private _getDadJoke(): void {
        // console.log(`_getDadJoke()`);
        const requestOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
            })
        };
        const dadJokeURL = 'https://icanhazdadjoke.com/';
        this._http
            .get(dadJokeURL, requestOptions)
            .pipe(
                take(1),
                tap((newDadJoke: IDadJokeResponse) => {
                    this.dadJoke = newDadJoke.joke ? newDadJoke.joke : null;
                })
            ).subscribe();
    }

}
